/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.7.0-v202501202143-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqContextApiClass {
 
   constructor() {}

  /**
   * @summary Create or update multiple labels
   * @param {string} itemId - ID of the item that the labels are associated with
   */
  public batchCreateContextLabels(
    body: Array<models.ContextLabelWithIdInputV1>,
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/labels/batch`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<Array<models.ContextLabelOutputV1>>;
  }

  /**
   * @summary Create a new comment associated with an Item
   * @param {string} itemId - ID of the item that the comment is associated with
   */
  public createContextComment(
    body: models.ContextCommentInputV1,
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/comments`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextCommentOutputV1>;
  }

  /**
   * @summary Create a new label associated with an item
   * @param {string} itemId - ID of the item that the label is associated with
   */
  public createContextLabel(
    body: models.ContextLabelInputV1,
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/labels`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextLabelOutputV1>;
  }

  /**
   * @summary Create a new numeric context associated with an item
   * @param {string} itemId - ID of the item that the numeric context is associated with
   */
  public createContextNumeric(
    body: models.ContextNumericInputV1,
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/numeric`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextNumericOutputV1>;
  }

  /**
   * @summary Create new opaque context associated with an item
   * @param {string} itemId - ID of the item that the opaque context is associated with
   */
  public createContextOpaque(
    body: models.ContextOpaqueInputV1,
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/opaque`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextOpaqueOutputV1>;
  }

  /**
   * @summary Create a new label
   */
  public createLabel(
    body: models.LabelInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/labels`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelOutputV1>;
  }

  /**
   * @summary Create a new label category
   */
  public createLabelCategory(
    body: models.LabelCategoryInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/labels/categories`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelCategoryOutputV1>;
  }

  /**
   * @summary Create a new numeric description
   */
  public createNumericDescription(
    body?: models.NumericDescriptionInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/numeric`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NumericDescriptionOutputV1>;
  }

  /**
   * @summary Delete or archive a comment
   * @param {string} itemId - ID of the item that the comment is associated with
   * @param {string} commentId - The comment ID to be deleted
   * @param {boolean} [_delete=false] - Permanently and irretrievably delete the comment
   */
  public deleteContextComment(
    {
      itemId,
      commentId,
      _delete
    } : {
      itemId: string,
      commentId: string,
      _delete?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(commentId)) {
      throw new Error("'commentId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/comments/${encodeURIComponent(String(commentId))}`,

      params: omitBy({
        ['delete']: _delete
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete or archive a label
   * @param {string} itemId - ID of the item that the label is associated with
   * @param {string} labelId - The context label ID to be deleted
   * @param {string} [tableId] - If provided, the ID of the Table Definition that will be used for permissions checking. The user needs read permissions on the provided table definition and theitem has to be in the table in order to create, update, or delete context. If not provided, the user must have read permissions on the item id the context is being applied to.
   */
  public deleteContextLabel(
    {
      itemId,
      labelId,
      tableId
    } : {
      itemId: string,
      labelId: string,
      tableId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(labelId)) {
      throw new Error("'labelId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/labels/${encodeURIComponent(String(labelId))}`,

      params: omitBy({
        ['tableId']: tableId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete or archive a numeric context
   * @param {string} itemId - ID of the item that the numeric context is associated with
   * @param {string} numericContextId - The context numeric ID to be deleted
   * @param {string} [tableId] - If provided, the ID of the Table Definition that will be used for permissions checking. The user needs read permissions on the provided table definition and theitem has to be in the table in order to create, update, or delete context. If not provided, the user must have read permissions on the item id the context is being applied to.
   */
  public deleteContextNumeric(
    {
      itemId,
      numericContextId,
      tableId
    } : {
      itemId: string,
      numericContextId: string,
      tableId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(numericContextId)) {
      throw new Error("'numericContextId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/numeric/${encodeURIComponent(String(numericContextId))}`,

      params: omitBy({
        ['tableId']: tableId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete or archive opaque context
   * @param {string} itemId - ID of the item that the opaque context is associated with
   * @param {string} opaqueId - The opaque ID to be deleted
   * @param {boolean} [_delete=false] - Permanently and irretrievably delete the opaque context
   * @param {string} [tableId] - If provided, the ID of the Table Definition that will be used for permissions checking. The user needs read permissions on the provided table definition and theitem has to be in the table in order to create, update, or delete context. If not provided, the user must have read permissions on the item id the context is being applied to.
   */
  public deleteContextOpaque(
    {
      itemId,
      opaqueId,
      _delete,
      tableId
    } : {
      itemId: string,
      opaqueId: string,
      _delete?: boolean,
      tableId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(opaqueId)) {
      throw new Error("'opaqueId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/opaque/${encodeURIComponent(String(opaqueId))}`,

      params: omitBy({
        ['delete']: _delete,
        ['tableId']: tableId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete a specific label.
   * @param {string} labelId - ID of the label
   */
  public deleteLabel(
    {
      labelId
    } : {
      labelId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(labelId)) {
      throw new Error("'labelId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/labels/${encodeURIComponent(String(labelId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete a specific label category.
   * @param {string} categoryId - ID of the category
   */
  public deleteLabelCategory(
    {
      categoryId
    } : {
      categoryId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(categoryId)) {
      throw new Error("'categoryId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/labels/categories/${encodeURIComponent(String(categoryId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete a numeric description
   * @param {string} numericDescriptionId
   */
  public deleteNumericDescription(
    {
      numericDescriptionId
    } : {
      numericDescriptionId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(numericDescriptionId)) {
      throw new Error("'numericDescriptionId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/numeric/${encodeURIComponent(String(numericDescriptionId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Get all label categories
   * @param {string} [parentId] - If set to the ID of a parent category then the child categories will be returned; if set to the empty GUID (00000000-0000-0000-0000-000000000000) only top level categories will be returned; if not specified all categories will be returned
   */
  public findLabelCategories(
    {
      parentId
    } : {
      parentId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/context/labels/categories`,

      params: omitBy({
        ['parentId']: parentId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelCategoryOutputListV1>;
  }

  /**
   * @summary Get all labels for a given category
   * @param {string} categoryId - ID of the category of labels to return
   * @param {string} [query] - Search for labels that contain this string in a case-insensitive manner. This is especially useful for autocomplete categories. Note that if this is specified only the first 20 results will be returned, ordered by name
   * @param {string} [parentId] - If set to the ID of a parent label then the child labels will be returned; if set to the empty GUID (00000000-0000-0000-0000-000000000000) only top level labels will be returned; if not specified all labels will be returned
   */
  public findLabels(
    {
      categoryId,
      query,
      parentId
    } : {
      categoryId: string,
      query?: string,
      parentId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(categoryId)) {
      throw new Error("'categoryId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/context/labels/${encodeURIComponent(String(categoryId))}`,

      params: omitBy({
        ['query']: query,
        ['parentId']: parentId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelOutputListV1>;
  }

  /**
   * @summary Get numeric description by ID
   * @param {string} [id]
   */
  public getNumericDescription(
    {
      id
    } : {
      id?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/context/numeric`,

      params: omitBy({
        ['id']: id
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.NumericDescriptionOutputV1>;
  }

  /**
   * @summary Update a comment
   * @param {string} itemId - ID of the item that the comment is associated with
   * @param {string} commentId - ID of the comment to update
   */
  public updateContextComment(
    body: models.ContextCommentInputV1,
    {
      itemId,
      commentId
    } : {
      itemId: string,
      commentId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(commentId)) {
      throw new Error("'commentId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/comments/${encodeURIComponent(String(commentId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextCommentOutputV1>;
  }

  /**
   * @summary Update opaque context
   * @param {string} itemId - ID of the item that the opaque context is associated with
   * @param {string} opaqueId - ID of the opaque context to update
   */
  public updateContextOpaque(
    body: models.ContextOpaqueInputV1,
    {
      itemId,
      opaqueId
    } : {
      itemId: string,
      opaqueId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(opaqueId)) {
      throw new Error("'opaqueId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/opaque/${encodeURIComponent(String(opaqueId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextOpaqueOutputV1>;
  }

  /**
   * @summary Update a label
   * @param {string} labelId
   */
  public updateLabel(
    body: models.LabelInputV1,
    {
      labelId
    } : {
      labelId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(labelId)) {
      throw new Error("'labelId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/labels/${encodeURIComponent(String(labelId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelOutputV1>;
  }

  /**
   * @summary Update a label category
   * @param {string} categoryId
   */
  public updateLabelCategory(
    body: models.LabelCategoryInputV1,
    {
      categoryId
    } : {
      categoryId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(categoryId)) {
      throw new Error("'categoryId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/labels/categories/${encodeURIComponent(String(categoryId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelCategoryOutputV1>;
  }

  /**
   * @summary Update a numeric description
   * @param {string} numericDescriptionId
   */
  public updateNumericDescription(
    body: models.NumericDescriptionInputV1,
    {
      numericDescriptionId
    } : {
      numericDescriptionId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(numericDescriptionId)) {
      throw new Error("'numericDescriptionId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/numeric/${encodeURIComponent(String(numericDescriptionId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextNumericOutputV1>;
  }

}


export const sqContextApi = new sqContextApiClass();
